.container {
  display: flex;
  flex-direction: row;
  font-size: 36px;

  .page {
    width: 90%;
    height: 100vh;
    background: #ffffff;
    display: flex;
    flex-direction: row;
  }

  .container.about-page,
  .container.portfolio-page,
  .container.contact-page {
    position: relative;
    left: 10%;
    top: 55%;
    transform: translateY(-50%);
    width: 80%;
    max-height: 90%;
    h1 {
      font-size: 48px;
      color: #ef6b00;
      font-weight: 400;
      position: relative;
      margin-top: 100px;
      margin-bottom: 20px;
      left: 10px;
    }

    p {
      text-align: justify;
      margin: 5px;
      padding: 5px;
      font-size: 16px;
      color: #7f80ad;
      font-family: sans-serif;
      font-weight: 300;
      min-width: fit-content;
      animation: pulse 1s;

      &:nth-of-type(1) {
        animation-delay: 1.1s;
      }

      &:nth-of-type(2) {
        animation-delay: 1.2s;
      }

      &:nth-of-type(3) {
        animation-delay: 1.3s;
      }
    }

    .text-zone {
      width: 48%;
      min-width: 350px;
      min-height: 90%;
    }

    .text-animate-hover {
      &:hover {
        color: #0d499c;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .container {
    flex-direction: column;
    
    .page {
      width: 100%;
      
    }

    .container.about-page,
    .container.portfolio-page,
    .container.contact-page {
      display: flex;
      flex-direction: row;
      left: 2%;
      top: 40%;
      
    }
  }
}

@media screen and (max-width: 1000px) {
  .page {
    overflow: auto;
    .container.about-page {
      display: flex;
      flex-direction: column;
      .text-zone {
        width: 90%;
        
      }
    }
  }
}
