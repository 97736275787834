@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&family=Source+Code+Pro:ital@1&display=swap');

@import "animate.css";

input,
textarea {
  font-family: 'Roboto', sans-serif;
}


