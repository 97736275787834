.nav-bar {
  width: 10%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  min-width: 140px;
  .logo {
    height: 100px;
    margin: 0 auto;
  }

  nav {
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 30px auto;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      line-height: 48px;
      width: 72px;
      height: 72px;
      position: relative;
      text-decoration: none;

      i {
        transition: all 0.3s ease-out;
      }

      &:hover {
        color: #ef6b00;

        svg {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }

      &:after {
        content: "";
        font-size: 12px;
        letter-spacing: 2px;
        position: absolute;
        display: block;
        width: 100%;
        text-align: center;
        opacity: 0;
        transition: all 0.3s ease-out;
      }

      &:first-child {
        &::after {
          content: "HOME";
        }
      }
    }

    a.about-link {
      &:after {
        content: "ABOUT";
      }
    }

    a.contact-link {
      &:after {
        content: "CONTACT";
      }
    }

    a.portfolio-link {
      &:after {
        content: "PORTFOLIO";
      }
    }

    a.active {
      svg {
        color: #ef6b00;
      }
    }
  }

  // to finish
  .socials {
    position: relative;
    bottom: -5%;
    text-align: center;
    ul {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        a {
          padding: 7px 0;
          display: block;
          font-size: 16px;
          line-height: 16px;

          &:hover svg {
            color: #ef6b00;
          }
        }
      }
    }
  }
}

.hamburger-icon,
.close-icon {
  display: none;
}

@media screen and (max-width: 1200px) {
  .nav-bar {
    background: transparent;
    position: initial;
    height: auto;
    min-height: auto;
    
    .logo {
      display: none;
    }
    
    ul,
    nav, .socials {
      display: none;
    }

    nav {
      width: 100%;
      height: 100%;
      background: #0D499C;
      top: 0;
      left: 0;
      position: fixed;
      z-index: 2;
      margin: 0;

      a {
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        
      }

      a:after {
        opacity: 1;
        position: initial;
        width: auto;
        margin-left: 10px;
      }

      a svg {
        color:#fff;
        opacity: 1 !important;
      }

      &.mobile-show {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    
  }

  .hamburger-icon,
    .close-icon {
      z-index: 1;
      display: block;
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 24px;
      cursor: pointer;
    }
}
